import { useAngularContext } from 'AngularContext';
import { useForm, yupResolver } from 'FrontRoyalReactHookForm';
import { useState, useEffect } from 'react';
/*
    This hook is used when setting up an edit form for an editableThingsList.

    It returns

    - formFunctions - the return of useForm
    - onSubmit - callback function to be passed into the form

    The `save` argument is optional.  It is only needed if you need to pass extra arguments
        into `proxy.save()`.  See AdminEditBillingTransaction/index.jsx for an example
*/

export default function useEditForm(args) {
    const { thing, editableThingsListViewModel, fromForm, toForm, metaData, validationSchema } = args;
    const [defaultValuesSet, setDefaultValuesSet] = useState(false);
    const iguanaKlass = thing.constructor;

    const $injector = useAngularContext();

    const formFunctions = useForm({
        defaultValues: null,
        resolver: yupResolver(validationSchema),
        mode: 'all',
        reValidateMode: 'onChange',
    });

    useEffect(() => {
        toForm(thing.asJson(), { injector: $injector }).then(values => {
            formFunctions.reset(values);
            setDefaultValuesSet(true);
        });
    }, [toForm, formFunctions.reset]);

    async function onSubmit(values) {
        let _metadata = metaData || {};
        if (metaData && typeof metaData === 'function') {
            _metadata = metaData();
        }

        const $http = $injector.get('$http');
        const isNew = !thing.id;
        const formData = new FormData();
        const record = {};

        const fromValues = await fromForm(values, { injector: $injector });
        Object.keys(fromValues).forEach(key => {
            const val = fromValues[key];
            if (val && val.constructor && val.constructor.name === 'File') {
                formData.append(key, val);
            } else {
                record[key] = val;
            }
        });
        formData.append('record', JSON.stringify(record));
        formData.append('meta', JSON.stringify(_metadata));

        const baseUrl = `${iguanaKlass.baseUrl}/${iguanaKlass.collection}`;
        const url = isNew ? `${baseUrl}.json` : `${baseUrl}/${thing.id}.json`;
        const request = {
            method: isNew ? 'POST' : 'PUT',
            url,
            data: formData,
            headers: {
                Accept: 'application/json',

                // On web we have to set Content-type to undefined here. When we
                // do that, the content-type ends up getting set to multipart/form-data
                // with an appropriate boundary due to $http and the browser fetch implementation.
                // But because capacitor overloads fetch, we need to explicitly set the header
                'Content-type': window.CORDOVA ? `multipart/form-data; boundary=--${Date.now()}` : undefined,
            },
        };

        $http(request).then(async response => {
            let result;
            try {
                result = response.data.contents[thing.constructor.collection][0];
            } catch (e) {
                throw new Error('No result found in response.');
            }
            thing.copyAttrs(result);
            const attrs = await toForm(thing.asJson(), { injector: $injector });
            formFunctions.reset(attrs);
            editableThingsListViewModel.onSaved(thing, isNew);
        });
    }

    // Since toForm can be asynchronous, there may not be defaultValues yet. In that case,
    // we should not yet render the form
    if (!defaultValuesSet) return null;

    return [formFunctions, onSubmit];
}
